import React from 'react'
import "../Styles/WebAdmin.css"
import flovation from "../Assets/new_logo.png"
import { getUserDetails, getsetStats } from "../Utility/AsyncStorageData";
import axios from "axios";
import { greet } from './Navbar';
import { greetController } from "../WebAppComponents/ControllerNavbar"

function Header(props) {

    // console.log("************Header************ ")
    var userDetails = getUserDetails();
    var stats = getsetStats() ?? {}


    const handleToogle = () => {
        if (props.show == 3) {
            greetController()
        }
        else {
            greet()
        }
        props.sendNavbarToParent ? props.sendNavbarToParent('close report') : console.log("")
    }


    return (
        <div className='Header'>
            <div className="menubars">
                <i class="fa-solid fa-bars" onClick={() => handleToogle()} ></i>
            </div>

            {
                props.show == 3 ? <></>
                    : <>
                        {/* <div className="logoimg">
                            <img src={flovation} alt="flovation_Logo" style={{width: "50px", marginTop:"0 10px"}} />
                        </div> */}
                        <div style={{ width: "90%", padding: "10px 0" }} className='com_name'>
                            {/* <h3>SuperManager</h3> */}
                            <h4 style={{ fontSize: 18 }}>{userDetails.comp_name}</h4>
                        </div>
                    </>
            }

            {props.show == 0 ?
                <div className="stats">
                    <h2 style={{ backgroundColor: "lightGreen", border: "2px solid green" }}>{stats.on_load ?? 0}</h2>
                    <h2 style={{ backgroundColor: "rgb(242, 187, 84)", border: "2px solid orange" }}>{stats.free_load ?? 0}</h2>
                    <h2 style={{ backgroundColor: "rgb(245, 143, 143)", border: "2px solid red" }}>{stats.zero_load ?? 0}</h2>
                    <h2 style={{ backgroundColor: "skyblue", border: "2px solid blue" }}>{stats.disconnected_device ?? 0}</h2>
                </div> : <></>}

            <div className='powered'>
                <i class="fa fa-copyright" style={{ fontSize: 10, paddingRight: 4 }}></i>
                <p>Powered by</p>
                <img style={{ width: "20px", margin: "0  0 0 5px" }}
                    src={require("../Assets/new_logo.png")}
                    alt="logo"
                />
                <p style={{ margin: 0, fontWeight: 600 }}>SuperManager</p>
            </div>
        </div>
    )
}

export default Header