import React, { useState, useRef, useEffect } from "react";
import "../../Styles/WebAdmin.css"
import ControllerNavbar from '../../WebAppComponents/ControllerNavbar'
import "../../Styles/DeviceTable.css"
import "../../Styles/UserCard.css"
import Header from "../../WebAppComponents/Header";
import axios from "axios";
import { getUserDetails, getDeviceId, setStats, removeDeviceId } from "../../Utility/AsyncStorageData";
import { useNavigate } from 'react-router-dom';


var runtimeid = 0
var statueBarInterval = 19;
var intervalId = 0;

function ControllerMachine() {

    let navigate = useNavigate();
    var userDetails = getUserDetails();

    console.log("**************ControllerDevices*****************")


    const gb_machine_id = useRef("");
    const [load, setLoad] = React.useState(true);
    const [error, setError] = React.useState('');
    const [graph, setGraph] = React.useState([]);
    const [runningListLog, setRunningListLog] = React.useState({});
    const [runningStatus, setRunningStatus] = React.useState({});
    const [colorWidthData, setColorWidthData] = React.useState([]);
    const [test, setTest] = React.useState(1);

    // const [search, setSearch] = useState("");

    // const [limit, setLimit] = useState(10);
    // const [total_count, setTotal_count] = useState(10);
    // var [offset_current, setOffset_current] = useState(1);

    // const prev = () => {
    //     console.log("perv")
    //     setOffset_current(offset_current - 1)
    // }

    // const next1 = () => {
    //     console.log("next1")
    //     setOffset_current(offset_current + 1)
    // }


    const handleFetchMachineList = async () => {
        try {

            var device_id = getDeviceId()
            // console.log("*********** handleFetchMachineList ***********")


            let url = process.env.REACT_APP_BASE_URL + "/sensor/getCompanyWiseSensorList";
            let body = {}
            if (device_id) {
                body.device_id = device_id
            }
            // console.log("===============getCompanyWiseSensorList======================", body)

            await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.access_token}`
                },
            }).then(response => {

                if (response.status === 200) {

                    if (response.data.statusCode === 0) {
                        // console.log("getCompanyWiseSensorList======================", response.data.result)

                        const machineIds = response.data.result.map(item => item.machine_id);
                        const machineIdsString = machineIds.map(item => `${item}`).join(', ');

                        // console.log("machineIds===================", machineIdsString);

                        const elements = machineIdsString.split(', ');
                        gb_machine_id.current = elements.map(element => `'${element}'`).join(', ');

                        //subscribeFCMTopic()

                        // console.log("response.data.resultresponse.data.resultresponse.data.result", Object.keys(response.data.result).length);

                        setGraph(response.data.result)
                        let temp = {};
                        let speed_temp = {};

                        removeDeviceId()
                        Runtime()

                    }

                    else {

                        setError(response.data.msg)
                        setTimeout(() => errortimer(), 2000)
                        console.log("error in getCompanyWiseSensorList: ", response.data.msg);
                    }

                    // setLoading(false);
                }
                else if (response.status == 403) {
                    navigate("/");
                    setLoad(false)
                }

            }).catch(error => {
                console.log("error: ", error);
                if (error.response.status == 403) {
                    navigate("/");
                }
                // Alert.alert("ERROR")
                setError("ERROR")
                setTimeout(() => errortimer(), 2000)
                //         setLoading(false);
                //         return
            })
        } catch (error) {
            console.log("error ", error)
            //     setLoading(false);
            //     throw error;
        }

    }


    // const Runtime = async () => {
    //     try {
    //         // console.log('*********** getMachineStatus api ***********');

    //         if (gb_machine_id.current.length != 0) {

    //             // console.log('*********** api hit ***********', d);

    //             let url = process.env.REACT_APP_BASE_URL + '/sensor/getMachineStatus';
    //             let body = {
    //                 // machineState: machineState,
    //                 machine_id: gb_machine_id.current
    //             };

    //             // body.machine_id = elements.map(element => `'${element}'`).join(', ');
    //             // console.log(" getMachineStatus body", body)
    //             await axios
    //                 .post(url, body, {
    //                     headers: {
    //                         authorization: `${userDetails.access_token}`,
    //                     },
    //                 })
    //                 .then(response => {
    //                     if (response.status === 200) {
    //                         if (response.data.statusCode === 0) {

    //                             setRunningListLog(response.data)
    //                             setStats(JSON.stringify(response.data))

    //                             setRunningStatus(response.data.list)


    //                             // console.log("statueBarInterval1", response.data.list)
    //                             statueBarInterval = statueBarInterval + 1;
    //                             if (statueBarInterval >= 20) {

    //                                 // console.log("statueBarInterval", statueBarInterval)
    //                                 statueBarInterval = 0;
    //                                 dashProgressBar()
    //                             }

    //                         }
    //                         else if (response.data.statusCode === 2) {

    //                             console.log("getMachineStatus api error", response.data.msg)
    //                         }
    //                         else {

    //                             console.log("getMachineStatus api error", response.data.msg)
    //                             setError("ERROR")
    //                             setTimeout(() => errortimer(), 2000);
    //                         }
    //                         setLoad(false)
    //                     }
    //                 })
    //                 .catch(error => {
    //                     // Alert.alert("ERROR")
    //                     console.log("ERROR", error)
    //                     if (error.response.status == 403) {
    //                         navigate("/");
    //                       }
    //                     setError('ERROR');
    //                     setTimeout(() => errortimer(), 2000);
    //                 });
    //             setLoad(false)
    //         }
    //         runtimeid = setTimeout(() => Runtime(), 30000)
    //     } catch (error) {
    //         console.log('error ', error);
    //         runtimeid = setTimeout(() => Runtime(), 30000)
    //         throw error;
    //     }
    //     setLoad(false);
    // }

    const Runtime = async () => {
        try {
            if (gb_machine_id.current.length !== 0) {
                let url = process.env.REACT_APP_BASE_URL + '/sensor/getMachineStatus';
                let body = {
                    machine_id: gb_machine_id.current,
                };

                await axios
                    .post(url, body, {
                        headers: {
                            authorization: `${userDetails.access_token}`,
                        },
                    })
                    .then(response => {
                        if (response.status === 200) {
                            const data = response.data;
                            if (data.statusCode === 0) {
                                setRunningListLog(data);
                                setStats(JSON.stringify(data));
                                setRunningStatus(data.list);

                                statueBarInterval++;
                                if (statueBarInterval >= 20) {
                                    statueBarInterval = 0;
                                    dashProgressBar();
                                }
                            } else {
                                console.log("API error:", data.msg);
                                setError("ERROR");
                                setTimeout(() => errortimer(), 2000);
                            }
                        }
                    })
                    .catch(error => {
                        console.log("ERROR", error.message || "Unknown error");
                        if (error.response && error.response.status === 403) {
                            navigate("/");
                        }
                        setError("ERROR");
                        setTimeout(() => errortimer(), 2000);
                    });

                setLoad(false);
            }
        } catch (error) {
            console.log("Unexpected error:", error.message || error);
        } finally {
            setLoad(false);
            runtimeid = setTimeout(() => Runtime(), 60000);
        }
    };

    const dashProgressBar = async () => {
        try {
            // console.log('*********** dashProgressBar api ***********');

            if (gb_machine_id.current.length != 0) {

                let url = process.env.REACT_APP_BASE_URL + '/shift/fetchLogWiseData';
                let a = ""
                for (let key of gb_machine_id.current) {
                    a = a + key.replace("'", "")
                }

                let body = {
                    machine_ids: a
                };

                // console.log(" dashProgressBar body", body)
                await axios
                    .post(url, body, {
                        headers: {
                            authorization: `${userDetails.access_token}`,
                        },
                    })
                    .then(response => {
                        if (response.status === 200) {
                            if (response.data.statusCode === 0) {
                                // setMachineData(response.data.list)
                                // setMachineStates(response.data)
                                setColorWidthData(response.data.result)
                                // console.log(response.data.result)
                            } else {

                                console.log("dashProgressBar api error", response.data.msg)
                                setError(response.data.msg)
                                setTimeout(() => errortimer(), 2000);
                            }
                        }
                        else if (response.status == 403) {
                            dashProgressBar();
                        }
                    })
                    .catch(error => {
                        console.log("ERROR", error)
                        navigate("/");

                        setError('ERROR');
                        setTimeout(() => errortimer(), 2000);
                    });
                // setLoading(false);
            }

        } catch (error) {
            console.log('error ', error);
            setError('ERROR');
            setTimeout(() => errortimer(), 2000);
            // statueBarInterval = setTimeout(() => dashProgressBar(), 30000)
            throw error;
        }
    }


    const machineTime = (mech_id, data) => {
        if (runningStatus[mech_id].machine_current_status == "D") {
            return (
                <h1 style={{ color: "blue", fontSize: "18px", margin: "0px", fontWeight: "600" }} className='clock'>{(runningStatus[mech_id].on_time)}</h1>
            )
        }
        else {
            if (runningStatus[mech_id].efficiency >= data.Y) {
                return (
                    <h1 style={{ color: "green", fontSize: "18px", margin: "0px", fontWeight: "600" }} className='clock'>{(runningStatus[mech_id].on_time)}</h1>
                )
            }
            else if (runningStatus[mech_id].efficiency >= data.R) {
                return (
                    <h1 style={{ color: "orange", fontSize: "18px", margin: "0px", fontWeight: "600" }} className='clock'>{(runningStatus[mech_id].on_time)}</h1>
                )
            }
            else {
                return (
                    <h1 style={{ color: "red", fontSize: "18px", margin: "0px", fontWeight: "600" }} className='clock'>{(runningStatus[mech_id].on_time)}</h1>
                )
            }
        }


    }

    const updateOnTimeInSec = () => {
        if (Object.keys(runningStatus).length) {
            setRunningStatus((prevData) => {
                const updatedData = { ...prevData }; // Create a shallow copy of the previous state
                for (let key of graph) {
                    // console.log("key.sensor_data" , updatedData, key.machine_id)
                    if (updatedData[key.machine_id] && updatedData[key.machine_id].machine_current_state === '2') {
                        const timeObject = new Date(
                            `2000-01-01T${updatedData[key.machine_id].on_time}`
                        );
                        timeObject.setSeconds(timeObject.getSeconds() + 1);
                        const newTimeString = timeObject.toLocaleTimeString('en-US', {
                            hour12: false,
                        });

                        // Create a new object for the specific item and update the on_time property
                        updatedData[key.machine_id] = {
                            ...updatedData[key.machine_id],
                            on_time: newTimeString,
                        };
                    }
                }
                return updatedData;
            });
        }

    }


    const machineStatus = (mech_id) => {
        // console.log(mech_id, runningStatus[mech_id])
        if (runningStatus[mech_id].machine_current_status == "D") {
            return (
                <div style={{ display: "inline-flex", width: "20%", justifyContent: "flex-end" }}>
                    <h6 style={{
                        backgroundColor: "blue",//BLUE
                        borderRadius: "50%",
                        width: 10,
                        height: 10, margin: "0px"

                    }}> </h6>
                </div>

            )
        }
        else {
            if (runningStatus[mech_id].machine_current_state == "0") {
                return ( //#FA8072"

                    <div style={{ display: "inline-flex", width: "20%", justifyContent: "flex-end" }}>
                        <h6 style={{
                            backgroundColor: "red",//Red
                            borderRadius: "50%",
                            width: 10,
                            height: 10, margin: "0px"

                        }}> </h6>
                    </div>
                )
            }
            else if (runningStatus[mech_id].machine_current_state == "1") {
                return (//"#EE9F27",orange

                    <div style={{ display: "inline-flex", width: "20%", justifyContent: "flex-end" }}>

                        <h6 style={{
                            backgroundColor: "orange",//Red
                            borderRadius: "50%",
                            width: 10,
                            height: 10, margin: "0px"

                        }}> </h6>
                    </div>
                )
            }
            else {
                return ( //"#5BB450", //GREEN 
                    <div style={{ display: "inline-flex", width: "20%", justifyContent: "flex-end" }}>
                        <h6 style={{
                            backgroundColor: "green",//orange
                            borderRadius: "50%",
                            width: 10,
                            height: 10, margin: "0px",

                        }}> </h6>
                    </div>
                )
            }
        }


    }


    clearInterval(intervalId);
    intervalId = setInterval(() => {
        updateOnTimeInSec();
    }, 1000);

    const errortimer = () => {
        setError(undefined)
    }

    const navbarRender = (data) => {
        // console.log('Data received from child:', data);
        setTest(!test)
    };

    useEffect(() => {
        handleFetchMachineList()
        return () => {
            clearInterval(runtimeid);
            clearInterval(intervalId); // Clean up the interval when the component unmounts
        };
    }, []);

    return (
        <div>

            <div className='main'>

                <div>
                    <ControllerNavbar />
                </div>
                {load != 0 ?
                    <div className='load'> <img src="https://tmmapi.9930i.com/sm_loader" alt="Computer man" style={{ height: "150px" }} />
                    </div> :

                    <div className='Admin_menu' style={{ marginTop: "20px" }}>
                        <div>
                            <Header show={3} sendNavbarToParent={navbarRender} />
                        </div>

                        {/* <input className="search" onChange={(e) => {
                            setSearch(e.target.value)
                        }} type="search" name="search" placeholder="Search.." /> */}

                        <div className='machines' >

                            {/* {graph.length ?
                                (graph.map((item, index) => (
                                    <div key={index} className='machine_card'>
                                        <div className='row1'>
                                            <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                                                <h4 style={{ textAlign: "center", width: "80%" }}>{item.sensor_name}</h4>

                                                {runningStatus.hasOwnProperty(item.machine_id) ? machineStatus(item.machine_id)
                                                    : <h6 style={{
                                                        backgroundColor: "white",
                                                        width: 30,
                                                        borderRadius: 50,
                                                    }}> </h6>
                                                }
                                            </div>

                                            <div style={{ marginTop: "5px", display: "flex", width: "100%" }}>
                                                <h6 style={{ width: "30%" }}>{item.device_name}</h6>
                                                <div style={{ display: "flex", width: "70%" }}>
                                                    <h6 style={{ textAlign: "right", width: "100%" }}>Shift : {((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) ? (runningStatus[item.machine_id].start_time + " - " + runningStatus[item.machine_id].end_time) : ("NA")}</h6>
                                                </div>
                                            </div>
                                        </div>

                                        <hr className='line'></hr>

                                        <div className='row2'>
                                            <h5 style={{ color: "#5b5656" }}>Run Time</h5>
                                            {((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) ? (machineTime(item.machine_id, item.alarm_color)) : ("--:--:--")}

                                            <h6 style={{ marginTop: "45px" }}>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) ? (runningStatus[item.machine_id].efficiency) : ("0")}%</h6>
                                        </div>

                                        <div style={{ display: 'flex', width: '100%', height: '10px', borderRadius: '10px', marginBottom: "3px" }}>
                                            {(Object.keys(colorWidthData).length && (colorWidthData[item.machine_id])) ?
                                                colorWidthData[item.machine_id].map(({ color, width }, index) => (
                                                    <div key={index} style={{ width, backgroundColor: color, borderRadius: (borderRadius(index, item.machine_id)) }} ></div> //(index == 0 ? '10px 0 0 10px' : (index == colorWidthData[item.sensor_data._id].length - 1 ? '0px 10px 10px 0px' : '0px')) 

                                                )) :
                                                <div key={index} style={{ width: "100%", backgroundColor: "gray", borderRadius: "10px", height: "10px" }} ></div>}
                                        </div>

                                        <hr className='line'></hr>

                                        <div className='row3' style={{ alignItems: "center" }}>
                                            <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                <h6 style={{ color: "#5b5656" }}>Best</h6>
                                                <h6>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) ? (runningStatus[item.machine_id].best_shift_efficency ?? "0") : ("0")}%</h6>
                                            </div>

                                        </div>
                                    </div>

                                    
                                ))) : (<></>)

                            } */}

                            <div className='device_table' style={{ marginTop: "20px", height: "80vh", alignItems: "flex-start" }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ borderRadius: "10px 0 0 10px" }}>Sno.</th>
                                            <th>Status</th>
                                            <th>Machine</th>
                                            <th>Device</th>
                                            <th>Company</th>
                                            {/* <th>Shift Status</th> */}
                                            <th>Shift</th>
                                            <th>Run Time</th>
                                            <th>Run %</th>
                                            <th style={{ borderRadius: " 0 10px 10px 0" }}>Best</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {graph.length ?
                                            (graph.map((item, index) => (
                                                <tr className="device_tr" key={index} >
                                                    <td style={{ borderRadius: "10px 0 0 10px" }}>{index + 1}</td>
                                                    <td> {runningStatus.hasOwnProperty(item.machine_id) ? machineStatus(item.machine_id)
                                                        : <h6 style={{ backgroundColor: "white", width: 30, borderRadius: 50, }}> </h6>
                                                    }</td>
                                                    <td>{item.sensor_name}</td>
                                                    <td>{item.device_name}</td>
                                                    <td>{item.company_name}</td>
                                                    {/* <td>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) ? (<h6 style={{ backgroundColor: ((runningStatus[item.machine_id].shift_status) ? "green" : "red"), borderRadius: "50%", width: 10, height: 10, margin: "0px" }}> </h6>) : ("NA")}</td> */}
                                                    <td style={{ color: (((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) && runningStatus[item.machine_id].shift_status) ? "black" : "red" }}>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) ? (runningStatus[item.machine_id].start_time + " - " + runningStatus[item.machine_id].end_time) : ("NA")}</td>
                                                    <td>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) ? (machineTime(item.machine_id, item.alarm_color)) : ("--:--:--")}</td>
                                                    <td>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) ? (runningStatus[item.machine_id].efficiency) : ("0")}%</td>
                                                    <td style={{ borderRadius: " 0 10px 10px 0" }}>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) ? (runningStatus[item.machine_id].best_shift_efficency ?? "0") : ("0")}% </td>
                                                </tr>
                                            )))
                                            : <></>
                                        }

                                    </tbody>
                                </table>
                            </div>

                        </div>

                        {/* <div className='page-data'>
                            <p>Showing {((offset_current * limit) - (limit - 1))} to {((offset_current * limit) > total_count) ? (total_count) : (offset_current * limit)} of {total_count} entries</p>
                        </div> */}

                        {/* <div className='pageinitation'>
                            {(offset_current > 1) ? (<button onClick={() => prev()}>back</button>) : (<></>)}

                            <button >{offset_current}</button>
                            {((offset_current * limit) < total_count) ? (<button onClick={() => next1()}>Next</button>) : (<></>)}
                        </div> */}
                    </div>
                }
            </div >
        </div>
    )
}

export default ControllerMachine

