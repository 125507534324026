import React from 'react'
import ControllerNavbar from '../../WebAppComponents/ControllerNavbar'
import "../../Styles/DeviceTable.css"
import "../../Styles/UserCard.css"
import Header from "../../WebAppComponents/Header";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { getUserDetails, getCompNum, removeCompNum, setDeviceId } from "../../Utility/AsyncStorageData";
import Report from '../../Utility/Report'
import Modal from "../../WebAppComponents/Modal";



function ControllerDevices() {

    var userDetails = getUserDetails();
    let navigate = useNavigate();

    const [error, setError] = React.useState('');
    const [test, setTest] = React.useState(1);
    const [deviceList, setDeviceList] = React.useState([])
    const [reportDeviceId, setReportDeviceId] = React.useState("");
    const [reportcompId, setReportcompId] = React.useState("");
    const [reportname, setReportname] = React.useState("");
    const [openExcel, setOpenExcel] = React.useState(false);
    const [load, setLoad] = React.useState(true);

    const handleFetchDevice = async () => {
        try {

            var comp_num = getCompNum()

            let url = process.env.REACT_APP_BASE_URL + "/device/companyWiseDeviceList";
            let body = {}
            if (comp_num) {
                body.comp_num = comp_num
            }
            // console.log("************companyWiseDeviceList************ ", body)
            await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.access_token}`
                },
            }).then(response => {

                if (response.status === 200) {

                    if (response.data.statusCode === 0) {
                        if (comp_num) {
                            setDeviceList(response.data.data[0].deviceDetails);
                            setLoad(false)
                        }
                        else
                            setDeviceList(response.data.data)

                        // console.log("************companyWiseDeviceList************ ", response.data.data)

                        setError(undefined)
                        removeCompNum()

                    }
                    else if (response.data.statusCode === 1) {
                        setError(undefined);
                    }


                    else {

                        setError(response.data.msg)
                        setTimeout(() => errortimer(), 2000)
                        console.log(" companyWiseDeviceList error: ", response.data.msg);
                    }

                    setLoad(false)
                }

                else if (response.status == 403) {
                    navigate("/");
                }
            }).catch(error => {
                console.log("error: ", error);
                if (error.response.status == 403) {
                    navigate("/");
                }

                setError("Slow or no internet connected. Please check your internet connection.")
                setTimeout(() => handleFetchDevice(), 500)
                return
            })
        } catch (error) {
            console.log("error1 ", error)
            setError("Slow or no internet connected. Please check your internet")
            setTimeout(() => handleFetchDevice(), 500)
            return
            // throw error;
        }

        setLoad(false)
    }

    const navbarRender = (data) => {
        // console.log('Data received from child:', data);
        setTest(!test)
    };

    const errortimer = () => {
        setError(undefined)
    }

    const openExcelFlie = (item) => {
        setOpenExcel(true)
        setReportname(item.company_name)
        setReportcompId(item.comp_num)
        setReportDeviceId(item.device_id)
    }
    const receiveDataFromChild = (data) => {
        setOpenExcel(false);
    };

    const navigateToMachine = (deviceId) => {
        setDeviceId(JSON.stringify(deviceId))
        navigate("/controllerMachine")
    }

    React.useEffect(() => {
        handleFetchDevice();
    }, []);

    return (
        <div>

            <div className='main'>

                <div>
                    <ControllerNavbar />
                </div>
                {load != 0 ?
                    <div className='load'> <img src="https://tmmapi.9930i.com/sm_loader" alt="Computer man" style={{ height: "150px" }} />
                    </div>
                    :

                    <div className='Admin_menu' style={{ marginTop: "20px" }}>
                        <Header show={3} sendNavbarToParent={navbarRender} />


                        <div className='device_table' style={{ marginBottom: "60px" }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ borderRadius: "10px 0 0 10px" }}>ID</th>
                                        <th>Name</th>
                                        <th> Company Name</th>
                                        <th>Machine</th>
                                        <th style={{ borderRadius: " 0 10px 10px 0" }}  >Report</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {deviceList.length ?
                                        (deviceList.map((item, index) => (
                                            <tr className="device_tr" key={index} >
                                                <td style={{ borderRadius: "10px 0 0 10px" }} onClick={() => navigateToMachine(item.device_id)}>{item.device_id}</td>
                                                <td onClick={() => navigateToMachine(item.device_id)}>{item.device_name}</td>
                                                <td onClick={() => navigateToMachine(item.device_id)}>{item.company_name}</td>
                                                <td onClick={() => navigateToMachine(item.device_id)}>{item.sensor_count}</td>
                                                <td className='action' style={{ borderRadius: " 0 10px 10px 0" }}>
                                                    <i className="fa fa-file-excel-o" onClick={() => openExcelFlie(item)} ></i>
                                                </td>
                                            </tr>
                                        )))
                                        : <></>
                                    }

                                </tbody>
                            </table>


                        </div>

                        <Modal isOpen={openExcel}>
                            <div>
                                <div className='report_download'>
                                    <h3>Report Download</h3>
                                    <i className="fa-solid fa-xmark" onClick={() => setOpenExcel(false)}></i>
                                </div>
                                <Report show={5} deviceData={reportDeviceId} company={reportcompId} reportName={reportname} sendDataToParent={receiveDataFromChild} />
                            </div>
                        </Modal>

                    </div>
                }
            </div >
        </div>
    )
}

export default ControllerDevices
