import React, { Suspense, startTransition } from 'react'
import Navbar from './Navbar'
import "../Styles/DeviceTable.css"
import "../Styles/UserCard.css"
import { getUserDetails } from "../Utility/AsyncStorageData";
import Header from "./Header";
import { useNavigate } from 'react-router-dom';


import flovation from "../Assets/logo1.png"

function WebSetting() {

  var userDetails = getUserDetails();
  let navigate = useNavigate();

  const [error, setError] = React.useState('');
  const [test, setTest] = React.useState(1);

  const navbarRender = (data) => {
    // console.log('Data received from child:', data);
    setTest(!test)
  };

  const errortimer = () => {
    setError(undefined)
  }

  return (
    <div className='main'>

      <div>
        <Navbar />
      </div>

      <div className='Admin_menu'>

        <Header show={1} sendNavbarToParent={navbarRender} />

        <Suspense fallback={<div>Loading...</div>}>
          <div className='setting' onClick={() => { navigate("/TVList"); }}>
            <span
              style={{
                fontSize: "1rem",
                fontWeight: "800",
                color: "black",
              }}
            >
              TV Dashboard Setup
            </span>
            <i className="fa fa-chevron-down" style={{ fontSize: "20px", color: "black" }}></i>
          </div>
        </Suspense>

        <div className='setting' onClick={() => { navigate("/webHaltReason"); }}>
          <span
            style={{
              fontSize: "1rem",
              fontWeight: "800",
              color: "black",
              textTransform: "capitalize"
            }}
          >
            Add machine halts reasons
          </span>
          <i className="fa fa-chevron-down" style={{ fontSize: "20px", color: "black" }}></i>
        </div>

        
          <div className='setting' onClick={() => { navigate("/AddProduct"); }}>
            <span
              style={{
                fontSize: "1rem",
                fontWeight: "800",
                color: "black",
                textTransform: "capitalize"
              }}
            >
              Add Product 
            </span>
            <i className="fa fa-chevron-down" style={{ fontSize: "20px", color: "black" }}></i>
          </div> 

      </div>
    </div >
  )
}

export default WebSetting
