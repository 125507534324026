const Version = [
    {
        ver: "2.0.11",
        des: "Add Report Format"
    },
    {
        ver: "2.0.10",
        des: "new logic to add product list in machine log "
    },
    {
        ver: "2.0.9",
        des: "Add counter in machine log page"
    },
    {
        ver: "2.0.8",
        des: "product list and edit add delete the product in web with comp num 267"
    },
    {
        ver: "2.0.7",
        des: "Owner and machine inchare page"
    },
    {
        ver: "2.0.6",
        des: "new ui red and blue"
    },
    {
        ver: "2.0.5",
        des: "Add gate value in temperature detail page"
    },
    {
        ver: "2.0.4",
        des: "show counter details and add temperature detail page with infinite scroll"
    },
    {
        ver: "2.0.3",
        des: "Temperature dashboard added on temp.flovation.in "
    },
    {
        ver: "2.0.1",
        des: "controller section added"
    },
    {
        ver: "1.0.1",
        des: " implement tvCode"
    }
]

export default Version;

//top is the latest one 