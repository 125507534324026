import React from 'react'
import Navbar from './Navbar'
import "../Styles/DeviceTable.css"
import "../Styles/UserCard.css"
import { getUserDetails } from "../Utility/AsyncStorageData";
import Header from "./Header";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Modal from "./Modal";
import { showToast } from './Toast';

function AddProduct() {

    var userDetails = getUserDetails();
    let navigate = useNavigate();

    const [test, setTest] = React.useState(1);
    const [productList, setProductList] = React.useState([])
    const [isAddVisible, setIsAddVisible] = React.useState(false)
    const [isDelete, setIsDelete] = React.useState(false)
    // const [reason, setReason] = React.useState({})
    const [product, setProduct] = React.useState({})

    const navbarRender = (data) => {
        // console.log('Data received from child:', data);
        setTest(!test)
    };



    const handleChange = (fieldName, value) => {
        setProduct((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    const editproduct = async (item) => {
        console.log("editproduct", item)
        setIsAddVisible(true)
        setProduct(item)
    }
    const handleproductList = async () => {
        try {

            let url = process.env.REACT_APP_BASE_URL + "/ws/listProductPart";
            let body = {
                comp_num: userDetails.comp_num,
            }
            // console.log("************companyWiseDeviceList************ ", body)
            await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.access_token}`
                },
            }).then(response => {

                if (response.status === 200) {

                    if (response.data.statusCode === 0) {
                        console.log("************companyWiseDeviceList************ ", response.data)
                        setProductList(response.data.list)

                    }
                    else if (response.data.statusCode === 1) {
                        showToast(response.data.msg, 3000, "red");
                    }


                    else {

                        showToast(response.data.msg, 3000, "red");
                        console.log(" companyWiseDeviceList error: ", response.data.msg);
                    }

                    // setLoading (false);
                }


                else if (response.status == 403) {
                    navigate("/");
                }

            }).catch(error => {
                console.log("error: ", error);
                showToast("Slow or no internet connected. Please check your internet connection.", 3000, "red");

                // Alert.alert("ERROR")

                return
            })
        } catch (error) {
            console.log("error1 ", error)
            showToast(error, 3000, "red");
            return
        }
    }

    const submitProduct = async () => {
        // console.log("reason", reason)
        try {

            let url = process.env.REACT_APP_BASE_URL + "/ws/productPartCruds";
            let body = {
                part_num: product.id,
                comp_num: userDetails.comp_num,
                part: product.part,
                // added_by: userDetails.user_id
            }

            console.log("reasonapi", body)
            await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.access_token}`
                },
            }).then(response => {

                if (response.status === 200) {

                    if (response.data.statusCode === 0) {
                        handleproductList()
                        setIsAddVisible(false)
                        setProduct({})
                        showToast(response.data.msg, 3000, "green");
                    }
                    else if (response.data.statusCode === 1) {
                        console.log(" submitReason error: ", response.data.msg);
                        showToast(response.data.msg, 3000, "red");
                    }


                    else {

                        showToast(response.data.msg, 3000, "red");
                        console.log(" submitReason error: ", response.data.msg);
                    }

                    // setLoading (false);
                }


                else if (response.status == 403) {
                    navigate("/");
                }

                setIsAddVisible(false)
            }).catch(error => {
                console.log("error: ", error);

                showToast(error, 3000, "red");
                return
            })
        } catch (error) {
            showToast(error, 3000, "red");
            return
        }

        setIsAddVisible(false)
        setProduct({})
    }

    const deleteProduct = async () => {
        try {

            let url = process.env.REACT_APP_BASE_URL + "/ws/productPartCruds";
            let body = {
                part_num: product.id,
                comp_num: userDetails.comp_num,
                part: product.part,
                is_active: "N"
            }

            console.log("reasonapi", body)
            await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.access_token}`
                },
            }).then(response => {

                if (response.status === 200) {

                    if (response.data.statusCode === 0) {
                        handleproductList()
                        setIsDelete(false)
                        setProduct({})
                        showToast(response.data.msg, 3000, "green");
                    }
                    else if (response.data.statusCode === 1) {
                        console.log(" submitReason error: ", response.data.msg);
                    }


                    else {

                        showToast(response.data.msg, 3000, "red");
                        console.log(" submitReason error: ", response.data.msg);
                    }

                    // setLoading (false);
                }


                else if (response.status == 403) {
                    navigate("/");
                }

                setIsAddVisible(false)
            }).catch(error => {
                console.log("error: ", error);
                showToast(error, 3000, "red");
                return
            })
        } catch (error) {
            console.log("error1 ", error)
            showToast(error, 3000, "red");
            return
        }

        setIsDelete(false)
        setProduct({})
    }

    React.useEffect(() => {
        handleproductList();
    }, []);

    return (
        <div className='main'>

            <div>
                <Navbar />
            </div>

            <div className='Admin_menu'>

                <Header show={1} sendNavbarToParent={navbarRender} />

                <h3 style={{ width: "100%", textAlign: "center", color: "rgb(5, 19, 120)", margin: 3 }}>Products</h3>
                <div className='addBlock1' onClick={() => { setIsAddVisible(true) }}>
                    <i class="fa fa-plus-square" ></i>
                </div>
                {productList == undefined ? <></> :
                    <div className='device_table'>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ borderRadius: "10px 0 0 10px" }}>S.No</th>
                                    <th>Products</th>
                                    <th style={{ borderRadius: " 0 10px 10px 0", textAlign: 'center' }} >Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {productList.map((item, index) => (
                                    <tr className="device_tr" key={index}>
                                        <td style={{ borderRadius: "10px 0 0 10px" }}>{index + 1}</td>
                                        <td >{item.part}</td>
                                        <td className='action' style={{ borderRadius: " 0 10px 10px 0", textAlign: 'center' }}>
                                            <i className="fa-solid fa-pen" onClick={() => { editproduct(item) }} ></i>
                                            <i className="fa-solid fa-trash-can" onClick={() => { setProduct(item); setIsDelete(true) }}></i>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>


                    </div>
                }

            </div>

            <Modal isOpen={isAddVisible}>

                <div style={{ display: "inline-flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <h2 style={{ margin: "0", padding: "0", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                        {Object.keys(product).length === 0 ? "Add Product" : "Edit Product"}
                    </h2>
                    <i className="fa fa-close" onClick={() => { setIsAddVisible(false); setProduct("") }}></i>
                </div>
                <div>
                    <div>
                        <label>Enter Product Name</label>
                        <input className='inputField'
                            type="text"
                            name="product"
                            value={product.part || ""}
                            onChange={(e) => handleChange("part", e.target.value)}
                        />
                    </div>
                    <button onClick={submitProduct} style={{
                        backgroundColor: "#252B42 ", height: "30px", borderRadius: "10px", textDecoration: "none",
                        border: "0", marginTop: "30px", color: "white", fontSize: "15px", fontWeight: "500"
                    }}>Submit</button>
                </div>
            </Modal>

            <Modal isOpen={isDelete}>
                <h2 style={{ margin: "0", padding: "0", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                    Are yoy sure you Delete {product.part} ?
                </h2>
                <div style={{ display: "inline-flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <h4 onClick={() => { setIsDelete(false); setProduct({}) }}>Close</h4>
                    <h4 onClick={deleteProduct}>Done</h4>
                </div>

            </Modal>
        </div >
    )
}

export default AddProduct
